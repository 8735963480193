import React, { FC } from 'react';
import { StickyContainer, Sticky } from 'react-sticky';

import { ListingsContainer } from 'components/listings/Container';
import { ArtworksGridContainer } from 'components/artworksGrid/Container';
import { ArtworkPreviewModalContainer, ModalData } from 'components/artworkPreviewModal/Container';
import { GridTitle } from 'components/artworksGrid/GridTitle';
import { OPGridTitle } from 'components/artworksGrid/OPGridTitle';
import { PromoText } from 'apps/homepage/components/PromoText';
import { MODAL_ID } from 'apps/homepage/utils/modal';
import { HomePageHero } from 'apps/homepage/components/HomePageHero';

import { ChromecastProvider } from 'context/ChromecastContext';
import { LegacyArtwork } from 'types';

type StateProps = {
  loggedIn: boolean;
};
type DispatchProps = {
  afterOpenModal: (data: ModalData, artwork: LegacyArtwork) => void;
};

type Props = DispatchProps & StateProps;

export const App: FC<Props> = ({ afterOpenModal, loggedIn }) => (
  <>
    <HomePageHero />
    <div className='container mt-3 mb-2'>
      {!loggedIn && <PromoText />}
      <div className='js-artwork-grid'>
        <GridTitle content='Artworks' url={Routes.artworks_path()} />
        <StickyContainer>
          <Sticky>
            {({ style, isSticky }) => (
              <div className='zi-2' style={{ ...style, backgroundColor: '#fff' }}>
                <ListingsContainer isSticky={isSticky} />
              </div>
            )}
          </Sticky>
          <div className='mt-2'>
            <ArtworksGridContainer type='curated' />
          </div>
          <ArtworksGridContainer {...{ type: 'op' }}>
            <OPGridTitle />
          </ArtworksGridContainer>
          <ChromecastProvider>
            <ArtworkPreviewModalContainer
              modalId={MODAL_ID}
              afterOpenModal={(modalData, artwork) => afterOpenModal(modalData, artwork)}
            />
          </ChromecastProvider>
        </StickyContainer>
      </div>
    </div>
  </>
);
